import { useLazyQuery, useMutation } from '@apollo/client';
import { Divider } from '@material-ui/core';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import confirm from 'reactstrap-confirm';
import {
  ACTIVE_AND_DELETED_ARRAY,
  LANGUAGE_ARRAY_WITH_STRING,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
  SORT_VALUES,
} from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, getTranslationLabel, urlSlugify } from '../../../utils/helper';
import { UPDATE_COMMUNITY_GROUP } from '../graphql/community.mutation';
import { COMMUNITY_GROUPS_SEARCH } from '../graphql/community.query';

const CommunityGroupsList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [groupsList, setGroupsList] = useState([]);
  const [groupsListPagination, setGroupsListPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    language: LANGUAGE_ARRAY_WITH_STRING[0],
    name: '',
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getGroupsListRequest, { loading: getGroupsListLoading }] = useLazyQuery(COMMUNITY_GROUPS_SEARCH, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_group_search)) {
        setGroupsList(data?.admin_community_group_search?.results);
        setGroupsListPagination(data?.admin_community_group_search?.pagination);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useMutation(UPDATE_COMMUNITY_GROUP, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_group_update)) {
        handleChangePage(parseInt(router?.query?.page, 10) || 1);
        toast.success(
          `Success! Community Group has been ${data?.admin_community_group_update?.active ? 'active' : 'Inactive'}.`
        );
      }
    },
    onError: (error) => {
      toast.error('Something went wrong!');
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = async (page) => {
    setPageInUrl(page);

    await getGroupsListRequest({
      variables: {
        searchDTO: {
          page,
          size: 20,
          ...filters,
          active: checkIfValueExist(filters?.active?.value),
          deleted: checkIfValueExist(filters?.deleted?.value),
          language: checkIfValueExist(filters?.language?.value),
          name: urlSlugify(filters?.name),
          ...SORT_VALUES,
        },
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = async () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters, name: '' });
    await getGroupsListRequest({
      variables: {
        searchDTO: {
          page: 1,
          size: 20,
          ...defaultFilters,
          active: checkIfValueExist(defaultFilters?.active?.value),
          deleted: checkIfValueExist(defaultFilters?.deleted?.value),
          language: checkIfValueExist(defaultFilters?.language?.value),
          name: '',
          ...SORT_VALUES,
        },
      },
    });
  };

  const handleActive = async (data) => {
    await markActiveInActiveRequest({
      variables: {
        groupId: data?.id,
        createDTO: {
          name: urlSlugify(data?.name),
          active: !data?.active,
        },
      },
    });
  };

  const handleDelete = async (data) => {
    const result = await confirm({
      title: `${data?.deleted ? 'Undelete' : 'Delete'} Group`,
      message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Group?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      await markActiveInActiveRequest({
        variables: {
          groupId: data?.id,
          createDTO: {
            name: urlSlugify(data?.name),
            active: data?.active,
            deleted: !data?.deleted,
          },
        },
      });
    }
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getGroupsListLoading || markActiveInActiveLoading} />

        <Container fluid>
          <Breadcrumbs title="Community" breadcrumbItem="Community Groups list" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={filters.language}
                      onChange={(value) => setFilters({ ...filters, language: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{groupsListPagination?.totalCount} Groups found</div>
              </div>
            </Col>

            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.COMMUNITY_GROUPS_ADD}>
                  <i className="mdi mdi-plus me-1" />
                  Add Group
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="table-responsive" style={{ minHeight: 300 }}>
                <Table className="project-list-table   table-centered table-borderless">
                  <thead>
                    <tr className="table-warning">
                      <th scope="col">S.No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Translation</th>
                      {/* <th scope="col">Post count</th>
                      <th scope="col">Comment count</th>
                      <th scope="col">Reaction count</th>
                      <th scope="col">View count</th> */}
                      <th scope="col">Members</th>
                      <th scope="col">Language</th>
                      <th scope="col">is Anonymous Post Allowed</th>
                      <th scope="col">is Anonymous Comment Allowed</th>
                      <th scope="col">Active</th>
                      <th scope="col">Deleted</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(groupsList) ? (
                      groupsList?.map((group, index) => {
                        return (
                          <tr key={`_test_${group.name}_`} className={!group?.active ? 'bg-danger' : ''}>
                            <td>{index + 1}</td>
                            <td>
                              {
                                <Link
                                  to={buildURL(SMKLinks.COMMUNITY_GROUPS_EDIT, {
                                    id: group?.id,
                                  })}>
                                  {group?.name}
                                </Link>
                              }
                              <br />
                              {group?.slug}
                            </td>
                            <td>
                              {getTranslationLabel(group?.translations[0])} <br />
                              {group?.translations[0]?.slug}
                            </td>
                            <td>{group?.members}</td>
                            <td>
                              <Badge className={PRINT_LANGUAGE_COLOR[group?.language]}>
                                {PRINT_LANGUAGE[group?.language]}
                              </Badge>
                            </td>
                            {/* <td>{group?.stats?.postCount}</td>
                            <td>{group?.stats?.commentCount}</td>
                            <td>{group?.stats?.reactionCount}</td>
                            <td>{group?.stats?.viewCount}</td> */}

                            <td>
                              <Badge className={group?.isAnonymousPostAllowed ? 'bg-success' : 'bg-danger'}>
                                {group?.isAnonymousPostAllowed ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={group?.isAnonymousCommentAllowed ? 'bg-success' : 'bg-danger'}>
                                {group?.isAnonymousCommentAllowed ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={group?.active ? 'bg-success' : 'bg-danger'}>
                                {group?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={group?.deleted ? 'bg-success' : 'bg-danger'}>
                                {group?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>

                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop" tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(group)}>
                                    <i
                                      className={`fas  ${
                                        group?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {group?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleDelete(group)}>
                                    <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                    {group?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem>
                                  <Divider className="my-2" />

                                  <Link
                                    to={buildURL(SMKLinks.COMMUNITY_GROUPS_EDIT, {
                                      id: group?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3" />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <TableError />
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={groupsListPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default CommunityGroupsList;
