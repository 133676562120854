export default {
  // api keys

  // URLs
  frontendBaseUrl: process.env.REACT_APP_FRONTEND_BASE_URL,
  // endpoints
  apiEndpoint: `${process.env.REACT_APP_BACKEND_BASE_API_URL}`,
  apiGraphqlEndpoint: `${process.env.REACT_APP_GRAPHQL_BACKEND_BASE_URL}`,

  resendOtpTimeout: 30, // in seconds

  production_url: process.env.REACT_APP_PRODUCTION_URL,
  cacheKey: process.env.REACT_APP_CACHE_KEY,
};
