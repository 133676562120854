import * as htmlEntities from 'html-entities';
import * as moment from 'moment';
import React from 'react';

import AuthService from 'common/auth.service';
import { isEmpty } from 'lodash';
import config from '../config/config';
import { api } from './axios';
import {
  API_BASE_URL,
  INCLUDES_NUMBER,
  INCLUDES_SYMBOL,
  INCLUDES_UPPERCASE,
  PermissionEntityEnum_SMK,
  PermissionEntityEnum_TPZ,
} from './constants';

// const Entities = require('' + '' + '').AllHtmlEntities;
// export const htmlEntities = new Entities();

const { htmlToText } = require('html-to-text');

export const titleCaseIfExists = (inputString) => {
  if (!inputString) {
    return '';
  }
  const str = inputString.toLowerCase().split(/[ -,_]/g);
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ').trim();
};

export const isStringEquals = (s1, s2) => {
  return String(s1) === String(s2);
};

export const isStringEqualsIgnoreCase = (s1, s2) => {
  return s1 && s2 && String(s1).toLowerCase() === String(s2).toLowerCase();
};

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const printDate = (date) => {
  return date ? formatDate(date, 'MMM DD, YYYY') : '';
};
export const printDateTime = (date) => {
  return date ? formatDate(date, 'MMM DD, YYYY - hh:mm a') : '';
};

export const printTime = (date) => {
  return date ? formatDate(date, 'hh:mm a') : '';
};
export const printDateTimeTwoLines = (date) => {
  return (
    <>
      {date && (
        <>
          {printDate(date)}
          <br />
          {printTime(date)}
        </>
      )}
    </>
  );
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getOfferingFullDetails = (id, allOfferings) => {
  if (!id) {
    return [];
  }
  const subject = allOfferings.find((o) => o.id === id);

  const array = [subject];

  let parent = subject?.parentOffering;
  while (parent) {
    // eslint-disable-next-line no-loop-func
    const offer = allOfferings.find((o) => o.id === parent.id);
    array.push(offer);
    parent = offer.parentOffering;
  }

  return array.sort((a, b) => a.level - b.level);
};

export const getOfferingFullName = (offerings) => {
  return offerings
    .filter((o) => o.level > 0)
    .map((o) => o.displayName)
    .join(' | ');
};

export const getOfferingFullNamewithArrow = (offerings) => {
  return offerings
    .filter((o) => o.level > 0)
    .map((o) => o.displayName)
    .join(' --> ');
};

export const getUserImageUrl = (filename) => {
  return filename && `${config.profileImageUrl}${filename}`;
};

export const getNameInitials = (string) => {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const getFullName = (contactDetail) => {
  return contactDetail ? `${contactDetail?.firstName} ${contactDetail?.lastName}` : '';
};
export const printPhoneNumber = (phoneNumber) => {
  return (
    <>
      +{phoneNumber.countryCode}-{phoneNumber.number}
    </>
  );
};
export const printCurrency = (number) => {
  return new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(number);
};

export const startOfDay = (date) => {
  return `${moment(date).format('YYYY-MM-DDT00:00:00')}Z`;
};

export const endOfDay = (date) => {
  return `${moment(date).format('YYYY-MM-DDT23:59:59')}Z`;
};

export const passwordPolicy = (pass) => {
  const alphaRegex = /\w+/;
  const numericRegex = /\d+/;
  return pass.length >= 8 && numericRegex.test(pass) && alphaRegex.test(pass);
};

export const urlSlugify = (input, length = -1) => {
  let text = input;
  if (text && text.length > 0) {
    text = text.replace(/'/g, '');
    text = text.replace(/[`~!@#$%^&*()_\-+=\[\]{};:‘’।'"\\|\/,.<>?\s]/g, ' ');
    text = text.replace(/^\s+|\s+$/gm, '');
    // text = text.replace(/\s+/g, "-");
    text = text
      .trim()
      .split(' ')
      .splice(0, length > 0 ? length : 100)
      .map((item) => {
        return item.trim();
      })
      .filter((item) => item.length > 0)
      .join('-');
    text = text.trim().toLowerCase();
  }
  return text;
};

export const cleanHTML = (html) => {
  let html1 = html;
  if (html && html.length > 0) {
    html1 = html.replace(/\\"/g, '"');
    html1 = html.replace(/style="[^\\"]*"/gi, '');
  }
  return html1;
};

export const convertHtmlToText = (html, removeNewLine = true) => {
  // let text = htmlToText(htmlEntities.decode(html), { preserveNewlines: false });
  let text = htmlToText(html, { preserveNewlines: false });
  text = text.replace(/"/g, '\\"');
  if (removeNewLine) {
    text = text.replace(/\n/g, ' ');
  }
  return text;
};

export const htmlDecode = (html) => {
  let text = '';
  try {
    text = cleanHTML(htmlEntities.decode(html));
  } catch (e) {
    text = '';
  }
  return text.replace(/(\r\n|\n|\r)/gm, '');
};

export const htmlEncode = (html) => {
  let text = '';
  try {
    text = cleanHTML(htmlEntities.encode(html));
  } catch (e) {
    text = '';
  }

  return text;
};

export const buildURL = (rawPath, pathParams = {}, queryParams = {}) => {
  let path = rawPath;
  Object.keys(pathParams).forEach((key) => {
    const t = pathParams[key];
    path = path?.replace(`:${key}`, t);
  });
  Object.keys(queryParams)
    .filter((key) => queryParams[key] !== null && typeof queryParams[key] !== 'undefined')
    .forEach((key, index) => {
      const param = encodeURIComponent(queryParams[key]);

      if (index === 0) {
        path += `?${key}=${param}`;
      } else {
        path += `&${key}=${param}`;
      }
    });
  return path;
};

export const isValidPassword = (val) =>
  val && val.length >= 8 && INCLUDES_NUMBER.test(val) && INCLUDES_SYMBOL.test(val) && INCLUDES_UPPERCASE.test(val);

export const getPermissions = (roles) => {
  // create entity type level mapping
  const auth = new AuthService();
  const source = auth.getAppSourceCookie();
  const permission_enum = source === 'schoolmykids' ? PermissionEntityEnum_SMK : PermissionEntityEnum_TPZ;

  const permissions = Object.values(permission_enum).reduce((acc, p) => {
    acc[p] = {
      entity: p,
      admin: false,
      import: false,
      export: false,
      reporting: false,
      publish: false,

      create: false,
      read: false,
      update: false,
      delete: false,
    };
    return acc;
  }, {});

  roles?.forEach((role) => {
    role.permissions?.forEach((p) => {
      const { entity, ...rest } = p;
      // Check if entity exists in PermissionEntityEnum
      if (permissions[entity]) {
        Object.entries(rest).forEach(([key, value]) => {
          permissions[entity][key] = permissions[entity][key] || value;
        });
      } else {
        console.warn(`Entity ${entity} does not exist in PermissionEntityEnum`);
      }
    });
  });

  return permissions;
};

export const checkIfValueExist = (value) => (value === null || value === ' ' ? undefined : value);

export const printSerialNumbers = (paginator) => {
  const { totalCount, size, currentPage } = paginator;
  const start = (currentPage - 1) * size + 1;
  const end = Math.min(currentPage * size, totalCount);

  const s_no = Array.from({ length: end - start + 1 }, (_, index) => start + index);
  return s_no;
};

export const stringInject = (str, data) => {
  if (typeof str === 'string' && data instanceof Array) {
    return str.replace(/({\d})/g, function(i) {
      return data[i.replace(/{/, '').replace(/}/, '')];
    });
  }
  if (typeof str === 'string' && data instanceof Object) {
    if (Object.keys(data).length === 0) {
      return str;
    }

    for (const key in data) {
      return str.replace(/({([^}]+)})/g, function(i) {
        const key = i.replace(/{/, '').replace(/}/, '');
        if (!data[key]) {
          return i;
        }

        return data[key];
      });
    }
  } else if (
    (typeof str === 'string' && data instanceof Array === false) ||
    (typeof str === 'string' && data instanceof Object === false)
  ) {
    return str;
  } else {
    return '';
  }
  return '';
};

// upload Images

export const showImage = (url) => {
  const { s3CloudfrontURL, prodURL } = getCurrentUserData();

  if (url?.startsWith(prodURL)) {
    return url;
  }
  const imageUrl = (url?.indexOf('/') === 0 ? '' : '/') + url;
  return s3CloudfrontURL + imageUrl;
};

export const handleUploadImage = (acceptedFile, setUploadImagePreview, setImageUploading) => {
  setImageUploading(true);
  const formData = new FormData();
  formData.append('file', acceptedFile);

  api({
    method: 'POST',
    url: `${API_BASE_URL}/attachment/upload`,
    data: formData,
  })
    ?.then((fileResponse) => {
      setUploadImagePreview(fileResponse);
      setImageUploading(false);
    })
    ?.catch((err) => {
      console.warn('error', err);
      setImageUploading(false);
    });
};

export const handleImageUploaded = async (uploadedImagePreview, acceptFile, forType, isAsset = false) => {
  if (!isEmpty(uploadedImagePreview)) {
    return await api({
      method: 'POST',
      url: `${API_BASE_URL}/${isAsset ? 'asset/admin' : 'attachment'}`,
      data: {
        ...uploadedImagePreview,
        forType,
      },
    })
      ?.then((file) => {
        return !isEmpty(acceptFile) ? (acceptFile == 'acceptID' ? file?.id : file) : file?.filename;
      })
      ?.catch((err) => {
        console.warn('err', err);
      });
  }
};

export function printNumber(input) {
  if (!input) {
    return input;
  }
  return input > 0 ? input?.toLocaleString('en-us') : '-';
}

export const printCurrencyTooltip = () => {
  return {
    formatter: (value) => {
      return printNumber(value);
    },
  };
};

export function printCurrencyFormat(value) {
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;
  const number = Number(value);

  if (isNaN(value) || !value) {
    return '-';
  } else if (number >= billion) {
    return (number % billion === 0 ? number / billion : (number / billion).toFixed(2)) + 'B';
  } else if (number >= million) {
    return (number % million === 0 ? number / million : (number / million).toFixed(2)) + 'M';
  } else if (number >= thousand) {
    return (number % thousand === 0 ? number / thousand : (number / thousand).toFixed(2)) + 'K';
  } else {
    return Number.isInteger(number) ? number?.toString() : number?.toFixed(2);
  }
}

export const printLabelsInCurrencyFormat = (props) => {
  return {
    formatter: (value) => {
      return printCurrencyFormat(value);
    },
    ...props,
  };
};

export const handleSaveAsset = async (file, type) => {
  if (!isEmpty(file)) {
    return await api({
      method: 'POST',
      url: `${API_BASE_URL}/asset/admin`,
      data: {
        ...file,
        forType: type,
      },
    })
      ?.then((file) => {
        return file;
      })
      ?.catch((err) => {
        console.warn('err', err);
      });
  }
};

export const getTranslationLabel = (obj) => {
  if (!obj) {
    return '';
  }

  return obj?.title || obj?.name || obj?.slug || '';
};

export const IMAGE_SIZES = {
  SMALL: 'thumbnailSmall',
  MEDIUM: 'thumbnailMedium',
  LARGE: 'thumbnailLarge',
  ORIGINAL: 'original',
};

export const getImageUrl = (attachmentObj, size) => {
  if (!attachmentObj) {
    return '';
  }
  const { s3CloudfrontURL, prodURL } = getCurrentUserData();

  if (attachmentObj[size]?.startsWith(prodURL)) {
    return attachmentObj[size];
  }

  const sizesOrder = Object.values(IMAGE_SIZES);

  if (!isEmpty(size)) {
    sizesOrder?.unshift(size);
  }

  for (let s of sizesOrder) {
    if (attachmentObj[s]?.startsWith(process.env.smkWebSiteDomain)) {
      return attachmentObj[s];
    }
    if (attachmentObj[s]) {
      const imageUrl = attachmentObj[s].startsWith('/') ? attachmentObj[s] : '/' + attachmentObj[s];
      return `${s3CloudfrontURL}${imageUrl}`;
    }
  }
};

export const getCurrentUserData = (appSource) => {
  const auth = new AuthService();
  const source = !isEmpty(appSource) ? appSource : auth.getAppSourceCookie();

  const sourceMap = {
    schoolmykids: {
      apiKey: process.env.REACT_APP_SMK_API_KEY,
      stgURL: process.env.REACT_APP_SCHOOLMYKIDS_WEB_URL,
      s3CloudfrontURL: process.env.REACT_APP_SMK_AWS_S3_CLOUDFRONT_URL,
      prodURL: 'https://schoolmykids.com',
    },
    theparentz: {
      apiKey: process.env.REACT_APP_TPZ_API_KEY,
      stgURL: process.env.REACT_APP_THEPARENTZ_WEB_URL,
      s3CloudfrontURL: process.env.REACT_APP_TPZ_AWS_S3_CLOUDFRONT_URL,
      prodURL: 'https://www.theparentz.com',
    },
    mediwatcher: {
      apiKey: process.env.REACT_APP_MEDIWATCHER_API_KEY,
      stgURL: process.env.REACT_APP_MEDIWATCHER_WEB_URL,
      s3CloudfrontURL: process.env.REACT_APP_MEDIWATCHER_AWS_S3_CLOUDFRONT_URL,
      prodURL: 'https://www.mediwatcher.com',
    },
  };

  return (
    sourceMap[source] || {
      apiKey: '',
      stgURL: '',
      s3CloudfrontURL: '',
      prodURL: '',
    }
  );
};
