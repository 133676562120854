// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
// Import Image
import * as moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  ACTIVE_AND_DELETED_ARRAY,
  BabyNameMetaInfoType,
  BABY_NAMES_GENDER_ARRAY,
  LANGUAGE_ARRAY_WITH_STRING,
  SORT_VALUES,
} from '../../../utils/constants';
import { buildURL, checkIfValueExist } from '../../../utils/helper';
import { SMKLinks } from '../../../utils/links';

const TranslateBabyNameList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();
  const languageValue = 1;

  const [babyNames, setBabyNames] = useState([]);
  const [babyNamesPagination, setBabyNamesPagination] = useState({});
  const [originList, setOriginList] = useState([]);
  const [religionList, setReligionList] = useState([]);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    startDate: moment().subtract(1, 'years'),
    endDate: moment(),
    id: undefined,
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    name: undefined,
    meaning: undefined,
    gender: undefined,
    origin: undefined,
    religion: undefined,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getBabyNamesRequest, { loading: getBabyNamesLoading }] = useRequest({
    url: `/baby-name/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setBabyNamesPagination(data?.pagination);
      setBabyNames(data?.results);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const data = {
      page,
      size: 20,
      ...filters,
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      gender: filters?.gender?.value,
      ...SORT_VALUES,
    };
    if (filters.gender === '') {
      data.gender = undefined;
    }
    if (filters.religion === '') {
      data.religion = undefined;
    }
    if (filters.origin === '') {
      data.origin = undefined;
    }

    getBabyNamesRequest({
      body: {
        ...data,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({
      ...defaultFilters,
      id: '',
      name: '',
      meaning: '',
      gender: '',
      origin: '',
      religion: '',
    });
    getBabyNamesRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        ...SORT_VALUES,
      },
    });
  };

  const [getOriginRequest, { loading: getOriginLoading }] = useRequest({
    url: `/baby-name/metaInfo/${BabyNameMetaInfoType.ORIGIN}`,
    method: 'GET',
    onSuccess: (data) => {
      // console.log(data);
      setOriginList(data);
    },
  });

  const [getReligionRequest, { loading: getReligionLoading }] = useRequest({
    url: `/baby-name/metaInfo/${BabyNameMetaInfoType.RELIGION}`,
    method: 'GET',
    onSuccess: (data) => {
      // console.log(data);
      setReligionList(data);
    },
  });

  useEffect(() => {
    getOriginRequest();
    getReligionRequest();
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <div className="page-content">
      <Loader isActive={getBabyNamesLoading || getReligionLoading || getOriginLoading} />
      {/* {getBabyNamesLoading && <Loader isActive />} */}
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="BabyNames" breadcrumbItem="Baby Names Translation List" />
        <Card>
          <CardBody>
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-2">
                  <label className="form-label">Published</label>
                  <Select
                    options={ACTIVE_AND_DELETED_ARRAY}
                    value={filters.active}
                    onChange={(value) => {
                      setFilters({ ...filters, active: value });
                    }}
                  />
                </div>
                <div className="col-lg-2">
                  <label className="form-label">Deleted</label>
                  <Select
                    options={ACTIVE_AND_DELETED_ARRAY}
                    value={filters.deleted}
                    onChange={(value) => setFilters({ ...filters, deleted: value })}
                  />
                </div>
                <div className="col-lg-2">
                  <label className="form-label">Created DateFrom</label>
                  <Input
                    type="date"
                    name="startDate"
                    placeholder="From Date"
                    value={filters?.startDate?.format('YYYY-MM-DD')}
                    max={moment().format('YYYY-MM-DD')}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        startDate: moment(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="col-lg-2">
                  <label className="form-label">Created DateTo</label>
                  <Input
                    type="date"
                    name="endDate"
                    placeholder="To Date"
                    value={filters?.endDate?.format('YYYY-MM-DD')}
                    max={moment().format('YYYY-MM-DD')}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        endDate: moment(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="col-lg-2">
                  <label className="form-label">Babyname</label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="name..."
                    value={filters.name}
                    onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                  />
                </div>
                <div className="col-lg-2">
                  <label className="form-label">Gender</label>
                  <Select
                    options={BABY_NAMES_GENDER_ARRAY}
                    value={filters.gender}
                    onChange={(value) => setFilters({ ...filters, gender: value })}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-2">
                  <label className="form-label">Origin</label>
                  <Select
                    options={originList?.map((s) => ({
                      ...s,
                      value: s.id,
                      label: s.name,
                    }))}
                    value={filters.origin && originList.find((t) => t.label === filters.origin)}
                    onChange={(item) => setFilters({ ...filters, origin: item.label })}
                  />
                </div>
                <div className="col-lg-2">
                  <label className="form-label">Religion</label>
                  <Select
                    options={religionList?.map((s) => ({
                      ...s,
                      value: s.id,
                      label: s.name,
                    }))}
                    value={filters.religion && religionList.find((t) => t.label === filters.religion)}
                    onChange={(item) => setFilters({ ...filters, religion: item.label })}
                  />
                </div>

                <div className="col-lg-2">
                  <label className="form-label">Language</label>
                  <Select
                    options={LANGUAGE_ARRAY_WITH_STRING}
                    value={LANGUAGE_ARRAY_WITH_STRING.find((t) => t.value === filters.language)}
                    onChange={(item) =>
                      setFilters({
                        ...filters,
                        language: item.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <a className="btn btn-primary me-4" onClick={applyFilters}>
                Apply Filters
              </a>
              <a className="" onClick={clearFilters}>
                Clear
              </a>
            </div>
          </CardBody>
        </Card>
        <Row className="my-5 align-items-center">
          <Col sm="4">
            <div className="search-box me-2 mb-2 d-inline-block">
              <div className="position-relative font-bold">{babyNamesPagination?.totalCount} Baby Names Found</div>
            </div>
          </Col>
          <Col sm="8">
            <div className="text-sm-end">
              <Link
                replace
                className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                to={buildURL(SMKLinks.TRANSLATE_BABY_NAME, { id: babyNames[0]?.id })}>
                <i className="mdi mdi-plus me-1" />
                Start Translation
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TranslateBabyNameList;
