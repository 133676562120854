import AuthService from 'common/auth.service';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import PageDetailCard from 'components/PageDetailsCard';
import SlugInput from 'components/SlugInput';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import SeoMetaData from 'containers/blog/BlogList/components/SeoMetaData';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { api } from 'utils/axios';
import {
  API_BASE_URL,
  LANGUAGE_ARRAY_WITH_STRING,
  LANGUAGE_VALUES_STRING,
  MODULE_TYPE,
  taxonomyType,
} from 'utils/constants';
import { getTranslationLabel, titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import config from '../../../config/config';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import ArticleSearch from '../components/articleSearch';

const UpdateTaxonomy = () => {
  const history = useHistory();
  const { id: taxonomyID } = useParams();
  toast.configure();

  const router = useRouter();
  const isSMKRoute = router?.location?.pathname?.includes('smk/taxonomy');
  const isTpzRoute = router?.location?.pathname?.includes('tpz/taxonomy');
  const isMediwatcherRoute = router?.location?.pathname?.includes('mediwatcher/taxonomy');

  const redirectToListing = (isReload) => {
    const url = isTpzRoute
      ? SMKLinks.TPZ_TAXONOMY_LISTING
      : isSMKRoute
      ? SMKLinks.SMK_TAXONOMY_LISTING
      : isMediwatcherRoute
      ? SMKLinks.MEDIWATCHER_TAXONOMY_LISTING
      : '';

    if (isReload) {
      window.location.reload();
    } else {
      history.replace(url);
    }
  };

  const auth = new AuthService();
  const source = auth.getAppSourceCookie();

  const [taxonomyDetails, settaxonomyDetails] = useState();
  const [calculatorCategories, setCalculatorCategories] = useState([]);

  const [getCalculatorTaxonomyList, { loading: getCalculatorTaxonomyListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setCalculatorCategories(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getTaxonomyRequest, { loading: getTaxonomyLoading }] = useRequest({
    url: `/common/admin/taxonomy/${taxonomyID}`,
    method: 'GET',
    onSuccess: (data) => {
      settaxonomyDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addTaxonomyRequest, { loading: addTaxonomyLoading }] = useRequest({
    url: `/common/admin/taxonomy`,
    method: 'POST',
    onSuccess: () => {
      toast.success('Success! Taxonomy has been added.');
      redirectToListing();
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [UpdateTaxonomyRequest, { loading: UpdateTaxonomyLoading }] = useRequest({
    url: `/common/admin/taxonomy/${taxonomyID}`,
    method: 'PUT',
    onSuccess: () => {
      toast.success('Success! Taxonomy has been updated.');
      redirectToListing(!isEmpty(taxonomyDetails));
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const getArticlesData = async (data) => {
    const articles = await Promise.all(
      data?.map(async (elem) => {
        try {
          const result = await api({
            method: 'GET',
            url: `${config.apiEndpoint}/article/admin/${elem?.id}`,
          });

          if (result?.id) {
            return {
              id: result?.id,
              title: result?.title,
            };
          }

          return null;
        } catch (error) {
          console.error(`Error fetching article with id ${elem?.id}`, error);
          return null;
        }
      })
    );

    return articles.filter((article) => article !== null);
  };

  const editInitialValues = () => {
    return {
      active: taxonomyDetails?.active,
      deleted: taxonomyDetails?.deleted,
      isPopular: taxonomyDetails?.isPopular,
      order: taxonomyDetails?.order,
      level: taxonomyDetails?.level,
      name: taxonomyDetails?.name,
      slug: taxonomyDetails?.slug,
      type: taxonomyDetails?.type,
      language: LANGUAGE_ARRAY_WITH_STRING?.find((e) => e?.value === taxonomyDetails?.language),
      description: taxonomyDetails?.description,
      metaData: !isEmpty(taxonomyDetails?.metaData)
        ? JSON.stringify({ ...taxonomyDetails?.metaData, articles: undefined }, null, 2)
        : '{}',
      articleMetaData: !isEmpty(taxonomyDetails?.metaData?.articles) ? taxonomyDetails?.metaData?.articles : [],

      parentId: !isEmpty(taxonomyDetails?.parent)
        ? {
            label: taxonomyDetails?.parent?.name,
            value: taxonomyDetails?.parent?.id,
          }
        : null,
      image: taxonomyDetails?.image || {},
      ...(isSMKRoute
        ? {
            translations: !isEmpty(taxonomyDetails?.translations) ? taxonomyDetails?.translations[0] : {},
            seoTitle: taxonomyDetails?.seo?.title || '',
            seoDescription: taxonomyDetails?.seo?.description || '',
            seoKeywords: !isEmpty(taxonomyDetails?.seo?.keywords)
              ? taxonomyDetails?.seo?.keywords?.split(', ')?.map((e) => ({
                  label: e,
                  value: e,
                }))
              : '',
            ogImage: taxonomyDetails?.seo?.ogImage || {},
          }
        : {}),
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    isPopular: false,
    language: LANGUAGE_ARRAY_WITH_STRING[0],
    order: '',
    level: '',
    name: '',
    slug: '',
    type: isSMKRoute ? taxonomyType?.CALCULATOR_CATEGORY : '',
    description: '',
    parentId: null,
    image: {},
    metaData: '{}',
    articleMetaData: '',

    ...(isSMKRoute
      ? {
          translations: '',
          seoTitle: '',
          seoDescription: '',
          seoKeywords: '',
          ogImage: {},
        }
      : {}),
  });

  const isJSON = (val) => {
    try {
      return typeof val === 'string' && JSON.parse(val);
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const isEmptyValue = (val) => (!isEmpty(val) ? val : undefined);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(taxonomyID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: async (values) => {
      if (isSMKRoute && !values?.parentId?.value && values?.level !== 0) {
        toast.error('Please provide primary category');
        return '';
      }

      if (!isJSON(values?.metaData)) {
        toast.error('Please enter a valid metaData JSON');
        return;
      }

      const metaDataValue = convertJSON(values?.metaData);
      const articlesData = !isEmpty(values?.articleMetaData)
        ? values?.articleMetaData?.map((e) => ({
            id: e?.id,
          }))
        : '';

      const data = {
        source,
        active: values?.active || false,
        deleted: values?.deleted || false,
        isPopular: values?.isPopular || false,
        order: values?.order || 0,
        level: values?.level || 0,
        name: isEmptyValue(values?.name),
        slug: values?.slug,
        type: values?.type,
        description: values?.description,
        parentId: values?.parentId?.value || null,
        metaData: !isEmpty(metaDataValue)
          ? {
              ...metaDataValue,
              articles: articlesData,
            }
          : !isEmpty(values?.articleMetaData)
          ? {
              articles: articlesData,
            }
          : '',
        language: values?.language?.value,
        ...(isSMKRoute
          ? {
              translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],
              seo: {
                title: !isEmpty(values?.seoTitle) ? values?.seoTitle : '',
                description: !isEmpty(values?.seoDescription) ? values?.seoDescription : '',
                keywords: !isEmpty(values?.seoKeywords) ? values?.seoKeywords?.map((e) => e?.value).join(', ') : '',
                ...(!isEmpty(values?.ogImage)
                  ? {
                      ogImageId: values?.ogImage?.id,
                      ogImage: undefined,
                    }
                  : { ogImageId: null }),
              },
            }
          : {}),
        ...(!isEmpty(values?.image)
          ? {
              imageId: values?.image?.id,
              image: undefined,
            }
          : { imageId: null }),

        seoTitle: undefined,
        seoDescription: undefined,
        seoKeywords: undefined,
        ogImage: undefined,
        articleMetaData: undefined,
      };

      if (isEmpty(taxonomyID)) {
        addTaxonomyRequest({
          body: {
            ...data,
          },
        });
      } else {
        UpdateTaxonomyRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      slug: Yup.string().required('Please provide slug'),
      type: Yup.string().required('Please provide type'),
      language: Yup.object()
        .required('Please provide language')
        .nullable(true),
    }),
  });

  useEffect(() => {
    async function fetchMetdData() {
      if (!isEmpty(taxonomyDetails?.metaData?.articles)) {
        const data = await getArticlesData(taxonomyDetails?.metaData?.articles);
        formik.setFieldValue('articleMetaData', data);
      }
    }
    fetchMetdData();
  }, [taxonomyDetails]);

  useEffect(() => {
    if (!isEmpty(taxonomyID)) {
      getTaxonomyRequest();
    }
  }, [taxonomyID]);

  useEffect(() => {
    if (isSMKRoute) {
      getCalculatorTaxonomyList({
        body: {
          page: 1,
          size: 5000,
          sortBy: 'id',
          sortOrder: 0,
          type: taxonomyType?.CALCULATOR_CATEGORY,
          language: formik.values.language?.value,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(formik.values.language) && isSMKRoute) {
      getCalculatorTaxonomyList({
        body: {
          page: 1,
          size: 5000,
          sortBy: 'id',
          sortOrder: 0,
          type: taxonomyType?.CALCULATOR_CATEGORY,
          language: formik.values.language?.value,
        },
      });
    }
  }, [formik.values.language, formik.values.type]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            addTaxonomyLoading || getTaxonomyLoading || UpdateTaxonomyLoading || getCalculatorTaxonomyListLoading
          }
        />
        <Container fluid>
          <Breadcrumbs
            title="Taxonomy"
            breadcrumbItem={`${isEmpty(taxonomyID) ? 'Add' : 'Edit'} ${
              isTpzRoute ? 'TPZ' : isSMKRoute ? 'SMK' : isMediwatcherRoute ? 'Mediwatcher' : ''
            }  Taxonomy`}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="name" className="form-label  ">
                            Name
                          </Label>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter name..."
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                          />
                          <FormFeedback className="d-block">{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6} className="mb-4">
                        <SlugInput
                          formik={formik}
                          ID={taxonomyID}
                          nameValue={formik.values.name}
                          formGroupClassName="mb-0"
                        />
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="order" className="form-label  ">
                            Order
                          </Label>
                          <Input
                            id="order"
                            name="order"
                            type="number"
                            className="form-control"
                            placeholder="Enter order..."
                            invalid={!!(formik.touched.order && formik.errors.order)}
                            {...formik.getFieldProps('order')}
                          />
                          <FormFeedback className="d-block">{formik.errors.order}</FormFeedback>
                        </FormGroup>
                      </Col>

                      {/* <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="level" className="form-label  ">
                            Level
                          </Label>
                          <Input
                            id="level"
                            name="level"
                            type="number"
                            className="form-control"
                            placeholder="Enter level..."
                            invalid={!!(formik.touched.level && formik.errors.level)}
                            {...formik.getFieldProps('level')}
                          />
                          <FormFeedback className="d-block">{formik.errors.level}</FormFeedback>
                        </FormGroup>
                      </Col> */}

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="description" className="form-label">
                            Description
                          </Label>
                          <Input
                            id="description"
                            name="description"
                            type="textarea"
                            rows={4}
                            className="form-control"
                            placeholder="Enter description..."
                            invalid={!!(formik.touched.description && formik.errors.description)}
                            {...formik.getFieldProps('description')}
                          />
                          <FormFeedback className="d-block">{formik.errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="metaData" className="form-label">
                            Meta Data
                          </Label>
                          <Input
                            id="metaData"
                            name="metaData"
                            type="textarea"
                            rows={6}
                            className="form-control"
                            placeholder="Enter metaData..."
                            invalid={!!(formik.touched.metaData && formik.errors.metaData)}
                            {...formik.getFieldProps('metaData')}
                          />
                          <FormFeedback className="d-block">{formik.errors.metaData}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <ArticleSearch formik={formik} />
                <Card>
                  <CardBody>
                    <h4>MetaData View</h4>
                    <Input
                      type="textarea"
                      disabled={true}
                      rows={10}
                      className="form-control"
                      placeholder="Enter metaData..."
                      value={JSON.stringify(taxonomyDetails?.metaData, null, 2)}
                    />
                  </CardBody>
                </Card>
                {isSMKRoute && <SeoMetaData formik={formik} forType={MODULE_TYPE.METADATA} />}
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      {taxonomyID ? 'Save Taxonomy' : 'Add Taxonomy'}
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        redirectToListing();
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>
                <PageDetailCard data={taxonomyDetails} />

                <Card className="border">
                  <CardBody>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="order" className="form-label  ">
                          Type
                        </Label>
                        <Select
                          options={Object.keys(taxonomyType)
                            ?.filter((e) =>
                              isSMKRoute
                                ? e?.toLowerCase() === taxonomyType?.CALCULATOR_CATEGORY
                                : e?.toLowerCase() !== taxonomyType?.CALCULATOR_CATEGORY
                            )
                            ?.map((e) => ({
                              label: titleCaseIfExists(e),
                              value: taxonomyType[e],
                            }))}
                          placeholder="Select type..."
                          value={
                            !isEmpty(formik.values.type)
                              ? {
                                  label: titleCaseIfExists(formik.values.type),
                                  value: formik.values.type,
                                }
                              : null
                          }
                          onChange={(e) => formik.setFieldValue('type', e?.value)}
                        />
                        <FormFeedback className="d-block">{formik.errors.type}</FormFeedback>
                      </FormGroup>
                    </Col>
                    {/* only applicable for calculator category */}
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="level" className="form-label  ">
                          Level (0 if Root Category)
                        </Label>
                        <Input
                          disabled={!isSMKRoute}
                          id="level"
                          name="level"
                          type="number"
                          className="form-control"
                          placeholder="Enter level..."
                          {...formik.getFieldProps('level')}
                          invalid={!!(formik.touched.level && formik.errors.level)}
                          value={taxonomyID ? formik.values.level : 0}
                        />
                        <FormFeedback className="d-block">{formik.errors.level}</FormFeedback>
                      </FormGroup>
                    </Col>
                    {isSMKRoute && formik.values.level !== 0 && (
                      <Col md={12}>
                        <CardTitle className="mb-3">Parent Category</CardTitle>
                        <FormGroup className="mb-4">
                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 999 }),
                            }}
                            placeholder="Select root category..."
                            options={calculatorCategories
                              ?.filter((d) => d.level === 0)
                              ?.map((e) => ({
                                label: e?.name,
                                value: e?.id,
                              }))}
                            value={formik.values.parentId}
                            onChange={(val) => formik.setFieldValue('parentId', val)}
                          />

                          <FormFeedback className="d-block">{formik.errors.parentId}</FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <CardTitle className="mb-3">Active </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`active_${option}`}
                              name="active"
                              className="form-check-input"
                              checked={formik.values.active === option}
                              onChange={() => formik.setFieldValue('active', option)}
                            />
                            <label className="form-check-label" htmlFor={`active_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                      <Col md={12}>
                        <CardTitle className="mb-3">Delete </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`deleted_${option}`}
                              name="deleted"
                              className="form-check-input"
                              checked={formik.values.deleted === option}
                              onChange={() => formik.setFieldValue('deleted', option)}
                            />
                            <label className="form-check-label" htmlFor={`deleted_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                      <Col md={12}>
                        <CardTitle className="mb-3">isPopular </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`isPopular_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`isPopular_${option}`}
                              name="isPopular"
                              className="form-check-input"
                              checked={formik.values.isPopular === option}
                              onChange={() => formik.setFieldValue('isPopular', option)}
                            />
                            <label className="form-check-label" htmlFor={`isPopular_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card className="border">
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <CardTitle className="mb-3">Language </CardTitle>
                        {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                          <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`language_${option?.value}`}
                              name="language"
                              className="form-check-input"
                              checked={formik.values.language?.value === option?.value}
                              onChange={() => {
                                formik.setFieldValue('language', option);
                                if (isSMKRoute) {
                                  formik.setFieldValue('parentId', '');
                                }
                              }}
                            />
                            <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                              {option?.label}
                            </label>
                          </div>
                        ))}
                      </Col>
                      {isSMKRoute && (
                        <Col md={12}>
                          <CardTitle className="mb-3">Translation </CardTitle>
                          <SmkAsyncSelect
                            isDisabled={formik.values.language?.value === LANGUAGE_VALUES_STRING.hindi}
                            acceptedKey={'name'}
                            acceptedValue={'id'}
                            placeholder="Type article name.."
                            onChange={(res) => {
                              formik.setFieldValue('translations', res);
                            }}
                            isValueSlugify={true}
                            fetchUrl={`${API_BASE_URL}/common/admin/taxonomy/search`}
                            filters={{
                              autoComplete: true,
                              active: true,
                              page: 1,
                              size: 500,
                              sortBy: 'id',
                              sortOrder: 0,
                              type: formik.values.type,
                              language:
                                formik.values.language?.value === LANGUAGE_VALUES_STRING.english
                                  ? LANGUAGE_VALUES_STRING.hindi
                                  : LANGUAGE_VALUES_STRING.english,
                            }}
                            searchKeyName="name"
                            value={
                              !isEmpty(formik?.values?.translations)
                                ? {
                                    label: getTranslationLabel(formik?.values?.translations),
                                    value: formik?.values?.translations?.id,
                                  }
                                : ''
                            }
                          />

                          {!isEmpty(formik?.values?.translations) && (
                            <div className="mt-3">
                              <div className="card bg-light ">
                                <div className="card-body">
                                  <a
                                    target="_blank"
                                    href={`/${
                                      isTpzRoute ? 'tpz' : isSMKRoute ? 'smk' : isMediwatcherRoute ? 'mediwatcher' : ''
                                    }/taxonomy/edit/${formik?.values?.translations?.id}`}>
                                    <p className="mb-1">
                                      <span className="text-dark">Language:</span>{' '}
                                      {formik?.values?.translations?.language}
                                    </p>
                                    <p className="mb-1">
                                      <span className="text-dark">ID:</span> {formik?.values?.translations?.id}
                                    </p>
                                    <p className="mb-1">
                                      <span className="text-dark">Title:</span>{' '}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: getTranslationLabel(formik?.values?.translations),
                                        }}></span>
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>

                <AssetImageUploader
                  forType={MODULE_TYPE.METADATA}
                  formik={formik}
                  fieldName={'image'}
                  label={'Select Image'}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateTaxonomy;
