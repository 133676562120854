// components
import { Divider } from '@material-ui/core';
import Loader from 'components/Loader';
import TableError from 'components/TableError';
import { invert } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { ACTIVE_AND_DELETED_ARRAY, AppSourceEnum, FEATURED_TYPE, SORT_VALUES, USER_ROLE } from 'utils/constants';
import { userSelector } from '../../../common/globalComponents/selectors';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
// helpers
import {
  buildURL,
  checkIfValueExist,
  convertHtmlToText,
  printDate,
  printDateTimeTwoLines,
  titleCaseIfExists,
} from '../../../utils/helper';
// links
import { SMKLinks } from '../../../utils/links';
// actions
import { getCities, getCountries, getRegions, getSubAreas } from '../actions';
import { citiesSelector, countriesSelector, regionsSelector, subAreasSelector } from '../selectors';
import { toast } from 'react-toastify';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
  user: userSelector,
});

const SchoolsFeatured = () => {
  const dispatch = useDispatch();

  const router = useRouter();
  const history = useHistory();

  const { countries, regions, cities, subAreas, user } = useSelector(stateSelector);

  const [schoolsFeaturedList, setSchoolsFeaturedList] = useState({});
  const [schoolsFeaturedPagination, setSchoolsFeaturedPagination] = useState({});

  const [getSchoolsFeaturedList, { loading: getSchoolsFeaturedLoading }] = useRequest({
    url: `/school/admin/featured/search`,
    method: 'POST',
    onSuccess: (data) => {
      setSchoolsFeaturedList(data?.results);
      setSchoolsFeaturedPagination(data?.pagination);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Feature Banner has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success!  Feature Banner has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const [staffUsers, setStaffUsers] = useState([]);
  const [createdByType, setCreatedByType] = useState({
    value: undefined,
    label: 'All',
  });

  const [getStaffUsersRequest, { loading: getStaffUsersLoading }] = useRequest({
    url: `/user/admin/search`,
    body: {
      active: true,
      deleted: false,
      staffOnly: true,
      type: createdByType?.value,
      page: 1,
      size: 100,
      appSource: AppSourceEnum.SCHOOLYKIDS,
    },
    method: 'POST',
    onSuccess: (data) => {
      setStaffUsers(
        data?.results?.map((d) => {
          return {
            ...d,
            label: `${d.firstName} ${d.lastName}\n${d.email}`,
            value: d.id,
          };
        })
      );
    },
    onError: (err) => {
      console.log(`Error occurred: ${err}`);
    },
  });

  useEffect(() => {
    getStaffUsersRequest();
  }, []);

  useEffect(() => {
    getStaffUsersRequest();
  }, [createdByType]);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    startDate: moment().subtract(5, 'years'),
    endDate: moment(),
    id: undefined,
    name: undefined,
    createdBy: undefined,

    country: undefined,
    region: undefined,
    city_district: undefined,
    subArea: undefined,
    featuredType: '',
  };
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
  }, []);

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const data = {
      page,
      size: 20,
      ...filters,
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      id: checkIfValueExist(filters?.id),
      // id: filters.schoolId ? parseInt(filters.schoolId, 10) : 0,
      ...SORT_VALUES,
    };

    if (filters.country == '') {
      data.country = undefined;
    } else if (filters.country) {
      data.country = filters.country.name;
    }
    if (filters.region == '') {
      data.region = undefined;
    } else if (filters.region) {
      data.region = filters.region.name;
    }
    if (filters.city_district == '') {
      data.city_district = undefined;
    } else if (filters.city_district) {
      data.city_district = filters.city_district.name;
    }
    if (filters.subArea == '') {
      data.subArea = undefined;
    } else if (filters.subArea) {
      data.subArea = filters.subArea.name;
    }

    if (filters.createdBy) {
      delete data.createdBy;
      data.userId = filters.createdBy.id;
      data.createdByUser = true;
    }

    getSchoolsFeaturedList({ body: data });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setCreatedByType({ value: undefined, label: 'All' });
    setFilters({
      ...defaultFilters,
      id: '',
      name: '',
      country: '',
      region: '',
      city_district: '',
      subArea: '',
      featuredType: '',
    });
    getSchoolsFeaturedList({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        id: defaultFilters.id,
        ...SORT_VALUES,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const onCreatedBySelect = (createdBy) => {
    if (!isEmpty(createdBy)) {
      setFilters({ ...filters, createdBy });
    }
  };

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      setFilters({ ...filters, country: country });
    } else {
      setFilters({ ...filters, country: undefined });
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      setFilters({ ...filters, region: region });
    } else {
      setFilters({ ...filters, region: undefined });
    }
  };

  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));

      setFilters({ ...filters, city_district: city });
    } else {
      setFilters({ ...filters, city_district: undefined });
    }
  };

  const onSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      setFilters({ ...filters, subArea: subArea });
    } else {
      setFilters({ ...filters, subArea: undefined });
    }
  };

  const handleActive = (data) => {
    markActiveInActiveRequest({
      url: `/school/admin/featured/${data?.id}`,
      body: {
        active: data?.active ? false : true,
      },
    });
  };
  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/school/admin/featured/${data?.id}`,
      body: {
        deleted: data?.deleted ? false : true,
      },
    });
  };

  const featuredTypeOptions = Object.keys(FEATURED_TYPE)?.map((val) => ({
    label: titleCaseIfExists(val),
    value: FEATURED_TYPE[val],
  }));

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getStaffUsersLoading || getSchoolsFeaturedLoading || markActiveInActiveLoading || deleteUnDeleteLoading
          }
        />

        <Container fluid>
          <Breadcrumbs title="Schools" breadcrumbItem="Schools Featured List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Published</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>

                  {user.entityPermissions?.school?.admin && (
                    <Col md={3}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="school_createdByType" className="form-label">
                          Created By Type
                        </Label>
                        <Select
                          id="school_createdByType"
                          options={[
                            {
                              label: 'All',
                              value: undefined,
                            },
                            {
                              label: 'Staff',
                              value: USER_ROLE.STAFF,
                            },
                            {
                              label: 'Other user',
                              value: USER_ROLE.USER,
                            },
                          ]}
                          value={createdByType}
                          onChange={(value) => setCreatedByType(value)}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {user.entityPermissions?.school?.admin && (
                    <Col md={3}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="school_createdBy" className="form-label">
                          Created By
                        </Label>
                        <Select
                          id="school_createdBy"
                          options={staffUsers}
                          value={filters.createdBy}
                          onChange={onCreatedBySelect}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </div>

                <Row>
                  <div className="col-lg-3 mb-4">
                    <label htmlFor="startDate" className="form-label">
                      Created Date - From
                    </label>
                    <Input
                      type="date"
                      id="startDate"
                      placeholder="From Date"
                      value={filters.startDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          startDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label htmlFor="endDate" className="form-label">
                      Created Date - To
                    </label>
                    <Input
                      type="date"
                      id="endDate"
                      placeholder="To Date"
                      value={filters.endDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          endDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="schoolId" className="form-label">
                      School Id
                    </label>
                    <Input
                      type="number"
                      id="schoolId"
                      placeholder="School Id"
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: Number(e.target.value) })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">School Name</label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="School Name"
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </Row>

                <Row>
                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_country" className="form-label">
                        Country
                      </Label>
                      <Select
                        isClearable
                        id="school_country"
                        options={countries}
                        value={filters.country}
                        onChange={onCountrySelect}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_state" className="form-label  ">
                        Region/State
                      </Label>

                      <Select
                        isClearable
                        id="school_state"
                        placeholder="Select..."
                        value={filters.region}
                        onChange={onRegionSelect}
                        options={regions}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_city" className="form-label  ">
                        City
                      </Label>
                      <Select
                        isClearable
                        id="school_city"
                        placeholder="Select..."
                        value={filters.city_district}
                        onChange={onCitySelect}
                        options={cities}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_area" className="form-label  ">
                        SubArea
                      </Label>
                      <Select
                        isClearable
                        id="school_area"
                        placeholder="Select..."
                        value={filters.subArea}
                        onChange={onSubAreaSelect}
                        options={subAreas}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="featuredType" className="form-label  ">
                        Featured Type
                      </Label>
                      <Select
                        id="featuredType"
                        isClearable
                        options={featuredTypeOptions}
                        value={
                          !isEmpty(filters?.featuredType)
                            ? featuredTypeOptions?.find((e) => e?.value === filters?.featuredType)
                            : ''
                        }
                        onChange={(obj) => setFilters({ ...filters, featuredType: obj?.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {schoolsFeaturedPagination?.totalCount} Schools Featured Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                {user?.entityPermissions?.school?.create && (
                  <Link
                    replace
                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                    to={SMKLinks.ADD_SCHOOL_FEATURED}>
                    <i className="mdi mdi-plus me-1" />
                    Add School Featured
                  </Link>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">#Id </th>
                        <th scope="col">School ID / School Name </th>
                        <th scope="col">Featured Type </th>
                        <th scope="col">City Name / Level </th>
                        <th scope="col">Created Date </th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">Claimed</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(schoolsFeaturedList) ? (
                        schoolsFeaturedList?.map((data, index) => (
                          <tr key={index}>
                            <td>{data.id}</td>
                            <td>
                              <Link
                                to={buildURL(SMKLinks.EDIT_SCHOOL_FEATURED, {
                                  id: data.id,
                                })}>
                                {data?.schoolId && `( ${data?.schoolId} )`} {convertHtmlToText(data?.school?.name)}
                              </Link>
                            </td>
                            <td>{titleCaseIfExists(invert(FEATURED_TYPE)[data.featuredType]?.replace('_', ' '))}</td>
                            <td>
                              {data?.city?.name} {data?.city?.level && `( ${data?.city?.level} )`}
                            </td>
                            <td>{printDate(data.createdDate)}</td>
                            <td>{printDate(data.expiryDate)}</td>

                            <td align="center">
                              <Badge className={data.claimed ? 'bg-success' : 'bg-danger'}>
                                {data?.claimed ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>

                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(data)}>
                                    <i
                                      className={`fas  ${
                                        data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {data?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleDelete(data)}>
                                    <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                    {data?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem>
                                  <Divider className="my-2" />

                                  <Link
                                    to={buildURL(SMKLinks.EDIT_SCHOOL_FEATURED, {
                                      id: data?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}

          <Paginate pageInfo={schoolsFeaturedPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default SchoolsFeatured;
