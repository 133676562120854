import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import confirm from 'reactstrap-confirm';
// actions
import { Divider } from '@material-ui/core';
import { getContactInquiryList, contactInquiryDelete, contactInquiryActionTaken } from '../actions';
// selectors
import { contactInquiryListSelector, contactInquiryPaginationSelector } from '../selectors';
// helpers
import { checkIfValueExist, printDateTimeTwoLines, titleCaseIfExists } from '../../../utils/helper';
// components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Paginate from '../../../components/Paginate';
import { toast } from 'react-toastify';
import Select from 'react-select';
import * as moment from 'moment';
import { invert, isEmpty } from 'lodash';
import TableError from 'components/TableError';
import { useRouter } from 'hooks/useRouter';
import { useHistory } from 'react-router-dom';
import { ACTION_TAKEN_ARRAY, ACTIVE_AND_DELETED_ARRAY, SORT_VALUES, contactInquirySources } from 'utils/constants';

const stateSelector = createStructuredSelector({
  inquiryList: contactInquiryListSelector,
  inquiryPagination: contactInquiryPaginationSelector,
});

const ContactInquiry = () => {
  const history = useHistory();
  const router = useRouter();
  const dispatch = useDispatch();
  toast.configure();

  const { inquiryList, inquiryPagination } = useSelector(stateSelector);

  const [activeTab, setActiveTab] = useState('tpz');

  const sourcesList = Object.keys(contactInquirySources)?.map((e) => ({
    label: titleCaseIfExists(e?.replace('_', ' ')),
    value: contactInquirySources[e],
  }));

  const defaultFilters = {
    actionTaken: { value: 0, label: 'All' },
    deleted: { value: null, label: 'All' },
    startDate: moment().subtract(1, 'year'),
    endDate: moment(),
    source: undefined,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page, currentActiveTab) => {
    const sources = sourcesList
      ?.filter(({ label }) => {
        const isTpz = label.toLowerCase().startsWith('tpz');
        if (currentActiveTab) {
          return currentActiveTab === 'tpz' ? isTpz : !isTpz;
        }
        return activeTab === 'tpz' ? isTpz : !isTpz;
      })
      ?.map((e) => e?.value);

    setPageInUrl(page);
    dispatch(
      getContactInquiryList.trigger({
        page,
        size: 50,
        ...filters,
        ...SORT_VALUES,
        actionTaken: checkIfValueExist(filters.actionTaken?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        source: checkIfValueExist(filters.source?.value),
        sources: sources,
      })
    );
  };

  const handleActionInquiry = async (data) => {
    const result = await confirm({
      title: 'Mark Action Taken',
      message: 'Are you sure, you want to mark action taken on this Inquiry?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      dispatch(
        contactInquiryActionTaken.trigger({
          id: data.id,
        })
      );
      toast.success('Success! Mark Action Taken has been Added.');
      handleChangePage(1);
    }
  };

  const handleDeleteInquiry = async (data) => {
    const result = await confirm({
      title: 'Delete Inquiry',
      message: 'Are you sure, you want to delete this Inquiry?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      dispatch(
        contactInquiryDelete.trigger({
          id: data.id,
        })
      );
      toast.success('Success! Inquiry has been Deleted.');
      handleChangePage(1);
    }
  };

  const composeEmailBasic = (data) => {
    window.location.href = `mailto:${data.email}?subject=Re: ${data.subject}&body=Dear ${data.name},
%0D%0A%0D%0A
Please find below the price for listing your school on our platform
%0D%0A%0D%0A
Detailed Listing -  A page about your school will be created on our platform covering Fees, logo, Admission Details, Curriculum, contact details, facilities etc): one time setup cost of Rs. 8000 + GST per school
%0D%0A%0D%0A--%0D%0A${data.message}%0D%0A`;
  };

  const composeEmailDetailed = (data) => {
    window.location.href = `mailto:${data.email}?subject=Re: ${data.subject}&body=Dear ${data.name},
%0D%0A%0D%0A %0D%0A%0D%0A--%0D%0A${data.message}%0D%0A`;
  };

  const applyFilters = () => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  };

  const clearFilters = () => {
    setFilters({
      ...defaultFilters,
      source: '',
    });

    const sources = sourcesList
      ?.filter(({ label }) => {
        const isTpz = label.toLowerCase().startsWith('tpz');
        return activeTab === 'tpz' ? isTpz : !isTpz;
      })
      ?.map((e) => e?.value);

    dispatch(
      getContactInquiryList.trigger({
        page: 1,
        size: 50,
        ...defaultFilters,
        ...SORT_VALUES,
        actionTaken: checkIfValueExist(defaultFilters.actionTaken?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        source: checkIfValueExist(defaultFilters.source?.value),
        sources: sources,
      })
    );
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="Contact Inquiry List" />

          <div>
            <Nav tabs>
              {['TPZ', 'Others']?.map((elem, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      className={activeTab?.toLowerCase() === elem?.toLowerCase() ? 'active' : ''}
                      onClick={() => {
                        setActiveTab(elem?.toLowerCase());
                        handleChangePage(1, elem?.toLowerCase());
                      }}>
                      {elem}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>
                <Card>
                  <CardBody>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-lg-3 mb-4">
                          <label className="form-label">Action Taken</label>
                          <Select
                            options={ACTION_TAKEN_ARRAY}
                            value={filters.actionTaken}
                            onChange={(value) => setFilters({ ...filters, actionTaken: value })}
                          />
                        </div>
                        <div className="col-lg-3 mb-4">
                          <label className="form-label">Deleted</label>
                          <Select
                            options={ACTIVE_AND_DELETED_ARRAY}
                            value={filters.deleted}
                            onChange={(value) => setFilters({ ...filters, deleted: value })}
                          />
                        </div>

                        <div className="col-lg-3 mb-4">
                          <label className="form-label">Created Date - From</label>
                          <Input
                            type="date"
                            name="startDate"
                            placeholder="From Date"
                            value={filters.startDate.format('YYYY-MM-DD')}
                            max={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setFilters({ ...filters, startDate: moment(e.target.value) })}
                          />
                        </div>
                        <div className="col-lg-3 mb-4">
                          <label className="form-label">Created Date - To</label>
                          <Input
                            type="date"
                            name="endDate"
                            placeholder="To Date"
                            value={filters.endDate.format('YYYY-MM-DD')}
                            max={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setFilters({ ...filters, endDate: moment(e.target.value) })}
                          />
                        </div>

                        <div className="col-lg-3 mb-4">
                          <label className="form-label">Source</label>
                          <Select
                            options={sourcesList?.filter(({ label }) => {
                              const isTpz = label.toLowerCase().startsWith('tpz');
                              return activeTab === 'tpz' ? isTpz : !isTpz;
                            })}
                            value={filters.source}
                            onChange={(value) => setFilters({ ...filters, source: value })}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <a className="btn btn-primary me-4" onClick={applyFilters}>
                        Apply Filters
                      </a>
                      <a className="" onClick={clearFilters}>
                        Clear
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </div>

          {inquiryPagination && (
            <Row className="mb-2">
              <Col sm="4">
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative font-bold">
                    {inquiryPagination?.totalCount} Contact Inquiry Found
                  </div>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs="12">
              <Col lg="12">
                <div className="">
                  <div className="table-responsive" style={{ minHeight: 300 }}>
                    <Table className="project-list-table table-centered table-borderless">
                      <thead>
                        <tr className="table-warning">
                          <th scope="col" style={{ width: '100px' }}>
                            #Id
                          </th>
                          <th scope="col">App Source</th>
                          <th scope="col">Name / Email / Phone</th>
                          <th scope="col">Subject / Message</th>
                          <th scope="col">Created Date</th>
                          <th scope="col">Action Taken</th>
                          <th scope="col">Deleted</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(inquiryList) ? (
                          inquiryList?.map((data, index) => (
                            <tr key={index}>
                              <td>{data.id}</td>
                              <td>{titleCaseIfExists(invert(contactInquirySources)[data.source])}</td>
                              <td>
                                <p className="mb-0 font-weight-medium">{data?.name}</p>
                                {data?.phoneNumber}
                                <br />
                                {data.email}
                              </td>
                              <td>
                                {data?.subject} <br />
                                <p>{data?.message}</p>
                              </td>
                              <td className="text-nowrap">{printDateTimeTwoLines(data.createdDate)}</td>
                              <td>
                                <Badge className={data.actionTaken ? 'bg-success' : 'bg-danger'}>
                                  {data.actionTaken ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop" tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    {!data?.actionTaken && (
                                      <DropdownItem onClick={() => handleActionInquiry(data)}>
                                        <i className="fas fa-pencil-alt text-success me-2 fa-fw" />
                                        Mark Action Taken
                                      </DropdownItem>
                                    )}
                                    {!data?.deleted && (
                                      <DropdownItem onClick={() => handleDeleteInquiry(data)}>
                                        <i className="fas fa-trash-alt text-danger me-2 fa-fw" />
                                        Delete Item
                                      </DropdownItem>
                                    )}

                                    {(!data?.actionTaken || !data?.deleted) && <Divider className="my-2" />}

                                    <DropdownItem onClick={() => composeEmailBasic(data)}>
                                      <i className="fa fa-envelope me-2 fa-fw" />
                                      Send Email - Basic
                                    </DropdownItem>
                                    <DropdownItem onClick={() => composeEmailDetailed(data)}>
                                      <i className="fa fa-envelope me-2 fa-fw" />
                                      Send Email - Detailed
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <TableError />
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>

          <Paginate pageInfo={inquiryPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default ContactInquiry;
