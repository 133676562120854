import { USER_ROLE } from '../../utils/constants';
import CommunityGroupsList from './groups';
import AddUpdateGroups from './groups/addUpdateGroups';
import COMMUNITY_LINKS from './links';

const COMMUNITY_ROUTES = [
  {
    path: COMMUNITY_LINKS.COMMUNITY_GROUPS_LIST,
    exact: true,
    component: CommunityGroupsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COMMUNITY_LINKS.COMMUNITY_GROUPS_ADD,
    exact: true,
    component: AddUpdateGroups,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COMMUNITY_LINKS.COMMUNITY_GROUPS_EDIT,
    exact: true,
    component: AddUpdateGroups,
    role: [USER_ROLE.ADMIN],
  },
];

export { COMMUNITY_ROUTES, COMMUNITY_LINKS };
