import React, { useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import Delimiter from '@editorjs/delimiter';
import Header from '@editorjs/header';
import NestedList from '@editorjs/nested-list';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import CustomImageUploader from './components/editor/editorImageUploader';
import simpleImageUmd from '@editorjs/simple-image';
import Underline from '@editorjs/underline';
import Strikethrough from 'editorjs-strikethrough';
import Columns from '@calumk/editorjs-columns';
import Paragraph from '@editorjs/paragraph';
import Embed from '@editorjs/embed';
import Marker from '@editorjs/marker';
import Hyperlink from 'editorjs-hyperlink';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import RawTool from '@editorjs/raw';

const ContentEditor = ({ editorInstance, forType }) => {
  const editorRef = useRef(null);

  const tools_list = {
    header: {
      class: Header,
      inlineToolbar: ['bold', 'italic', 'hyperlink', 'marker', 'underline', 'strikethrough'],
      tunes: ['anyTuneName'],
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: ['bold', 'italic', 'hyperlink', 'marker', 'underline', 'strikethrough'],
      tunes: ['anyTuneName'],
      toolbox: {
        title: 'Paragraph',
      },
    },
    anyTuneName: {
      class: AlignmentTuneTool,
      config: {
        default: 'left',
        blocks: {
          header: 'left',
          list: 'left',
        },
      },
    },
    raw: RawTool,
    hyperlink: {
      class: Hyperlink,
      inlineToolbar: true,
      config: {
        shortcut: 'CMD+L',
        target: '_blank',
        rel: 'nofollow',
        availableTargets: ['_blank', '_self'],
        availableRels: ['author', 'noreferrer', 'nofollow'],
        validate: false,
        icon:
          '<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.9 12c0-1.11.9-2 2-2h4v2H5.9c-.55 0-1 .45-1 1s.45 1 1 1H10v2H5.9c-1.1 0-2-.9-2-2zM18.1 12c0 1.11-.9 2-2 2h-4v-2h4.1c.55 0 1-.45 1-1s-.45-1-1-1H14V8h2.1c1.1 0 2 .9 2 2z" fill="currentColor"/><path d="M10 13v-2h4v2h-4zM5.9 8H10V6H5.9C3.78 6 2 7.78 2 9.9c0 2.11 1.78 3.9 3.9 3.9H10v-2H5.9c-.55 0-1-.45-1-1s.45-1 1-1zM18.1 6H14v2h4.1c2.11 0 3.9 1.78 3.9 3.9 0 2.11-1.78 3.9-3.9 3.9H14v2h4.1c2.21 0 4-1.79 4-4s-1.79-4-4-4z" fill="currentColor"/></svg>',
      },
    },
    marker: {
      class: Marker,
      shortcut: 'CMD+SHIFT+M',
    },
    embed: {
      class: Embed,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    list: {
      class: NestedList,
      inlineToolbar: true,
      tunes: ['anyTuneName'],
      config: {
        defaultStyle: 'ordered',
      },
    },
    table: {
      class: Table,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    delimiter: {
      tunes: ['anyTuneName'],
      class: Delimiter,
    },
    quote: {
      class: Quote,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    image: {
      class: simpleImageUmd,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    underline: {
      class: Underline,
      tunes: ['anyTuneName'],
      inlineToolbar: true,
    },
    strikethrough: {
      class: Strikethrough,
      tunes: ['anyTuneName'],
      shortcut: 'CMD+SHIFT+X',
      inlineToolbar: true,
    },
    customBlock: {
      class: CustomImageUploader,
      tunes: ['anyTuneName'],
      inlineToolbar: false,
      config: {
        forType,
      },
    },
  };

  useEffect(() => {
    editorInstance.current = new EditorJS({
      holder: 'editorjs_editor',
      autofocus: true,
      placeholder: 'Let`s write an awesome story!',
      tools: {
        ...tools_list,
        columns: {
          class: Columns,
          config: {
            EditorJsLibrary: EditorJS,
            tools: {
              ...tools_list,
            },
          },
        },
      },
    });

    editorRef.current = editorInstance.current;

    return () => {
      if (editorInstance.current) {
        editorInstance.current.destroy();
        editorInstance.current = null;
      }
    };
  }, [editorInstance]);

  return <div className="py-4 ps-5" id="editorjs_editor"></div>;
};

export default ContentEditor;
