import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
// components
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { ACTION_TAKEN_ARRAY, ACTIVE_AND_DELETED_ARRAY, SORT_VALUES } from 'utils/constants';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import SchoolAutoComplete from '../../../components/SchoolAutoComplete';
import config from '../../../config/config';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';

// helpers
import { checkIfValueExist, convertHtmlToText, printDateTimeTwoLines } from '../../../utils/helper';
import { getCities, getCountries, getRegions, getSubAreas } from '../actions';
import { citiesSelector, countriesSelector, regionsSelector, subAreasSelector } from '../selectors';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
});

const AdmissionInquiryList = () => {
  const router = useRouter();
  const history = useHistory();
  const dispatch = useDispatch();
  toast.configure();

  const [admissionInquiry, setAdmissionInquiry] = useState([]);
  const [admissionPagination, setAdmissionPagination] = useState({});
  const [claimedSchoolsOnly, setClaimedSchoolsOnly] = useState();
  const [selectedSchool, setSelectedSchool] = useState(null);
  const { countries, regions, cities, subAreas } = useSelector(stateSelector);

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
  }, []);

  const defaultFilters = {
    actionTaken: { value: -1, label: 'All' },
    active: true,
    deleted: { value: null, label: 'All' },
    startDate: moment().subtract(1, 'year'),
    endDate: moment(),

    countryId: undefined,
    regionId: undefined,
    cityId: undefined,
    subAreaId: undefined,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getAdmissionInquiryRequest, { loading: getAdmissionInquiryLoading }] = useRequest({
    url: `/school/admin/admissionInquiry/search`,
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      setAdmissionPagination(data?.pagination);
      setAdmissionInquiry(data?.results);
    },
  });

  const [markActionTakenRequest, { loading: markActionTakenLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      // console.log(data);
      handleChangePage(1);
      toast.success('Success! Mark Action Taken has been added.');
    },
  });

  const [deleteAdmisionInquiryRequest, { loading: deleteAdmisionInquiryLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      // console.log(data);
      handleChangePage(1);
      toast.success('Success! Admission Inquiry has been deleted.');
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const data = {
      ...filters,
      claimedSchoolsOnly: claimedSchoolsOnly ? true : undefined,
    };

    if (filters.countryId == '') {
      data.countryId = undefined;
    } else if (filters.countryId) {
      data.countryId = filters.countryId.id;
    }
    if (filters.regionId == '') {
      data.regionId = undefined;
    } else if (filters.regionId) {
      data.regionId = filters.regionId.id;
    }
    if (filters.cityId == '') {
      data.cityId = undefined;
    } else if (filters.cityId) {
      data.cityId = filters.cityId.id;
    }
    if (filters.subAreaId == '') {
      data.subAreaId = undefined;
    } else if (filters.subAreaId) {
      data.subAreaId = filters.subAreaId.id;
    }

    getAdmissionInquiryRequest({
      body: {
        page,
        ...data,
        size: 50,
        ...SORT_VALUES,
        actionTaken: checkIfValueExist(filters?.actionTaken?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
      },
    });
  };

  const markActionTaken = (inquiry) => {
    markActionTakenRequest({
      url: `/school/admin/admissionInquiry/markActionTaken/${inquiry.id}`,
    });
  };

  const markDeleted = (inquiry) => {
    deleteAdmisionInquiryRequest({
      url: `/school/admin/admissionInquiry/delete/${inquiry.id}`,
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({
      ...defaultFilters,
      countryId: '',
      regionId: '',
      cityId: '',
      subAreaId: '',
    });
    getAdmissionInquiryRequest({
      body: {
        ...defaultFilters,
        ...SORT_VALUES,
        page: 1,
        size: 50,
        actionTaken: checkIfValueExist(defaultFilters?.actionTaken?.value),
        deleted: checkIfValueExist(defaultFilters?.deleted?.value),
        claimedSchoolsOnly: undefined,
      },
    });
    setSelectedSchool(null);
    setClaimedSchoolsOnly(false);
  };

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      setFilters({ ...filters, countryId: country });
    } else {
      setFilters({ ...filters, countryId: undefined });
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      setFilters({ ...filters, regionId: region });
    } else {
      setFilters({ ...filters, regionId: undefined });
    }
  };

  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));

      setFilters({ ...filters, cityId: city });
    } else {
      setFilters({ ...filters, cityId: undefined });
    }
  };

  const onSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      setFilters({ ...filters, subAreaId: subArea });
    } else {
      setFilters({ ...filters, subAreaId: undefined });
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={deleteAdmisionInquiryLoading || markActionTakenLoading || getAdmissionInquiryLoading} />

        <Container fluid>
          <Breadcrumbs title="Schools" breadcrumbItem="Admission Inquiries List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label className="form-label">Action Taken</label>
                    <Select
                      options={ACTION_TAKEN_ARRAY}
                      value={filters.actionTaken}
                      onChange={(value) => setFilters({ ...filters, actionTaken: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Created Date - From</label>
                    <Input
                      type="date"
                      name="startDate"
                      placeholder="From Date"
                      value={filters.startDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          startDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Created Date - To</label>
                    <Input
                      type="date"
                      name="endDate"
                      placeholder="To Date"
                      value={filters.endDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          endDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">School</label>

                    <SchoolAutoComplete
                      selectedSchool={selectedSchool}
                      onSelectSchool={(school) => {
                        if (school?.id > 0) {
                          setFilters({ ...filters, schoolId: school.id });
                        } else {
                          const newFilters = { ...filters };
                          delete newFilters.schoolId;
                          setFilters(newFilters);
                        }
                        setSelectedSchool(school);
                      }}
                    />
                  </div>
                </div>

                <Row className="mt-4">
                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_country" className="form-label">
                        Country
                      </Label>
                      <Select
                        isClearable
                        id="school_country"
                        options={countries}
                        value={filters.countryId}
                        onChange={onCountrySelect}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_state" className="form-label  ">
                        Region/State
                      </Label>

                      <Select
                        isClearable
                        id="school_state"
                        placeholder="Select..."
                        value={filters.regionId}
                        onChange={onRegionSelect}
                        options={regions}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_city" className="form-label  ">
                        City
                      </Label>
                      <Select
                        isClearable
                        id="school_city"
                        placeholder="Select..."
                        value={filters.cityId}
                        onChange={onCitySelect}
                        options={cities}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_area" className="form-label  ">
                        SubArea
                      </Label>
                      <Select
                        isClearable
                        id="school_area"
                        placeholder="Select..."
                        value={filters.subAreaId}
                        onChange={onSubAreaSelect}
                        options={subAreas}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <FormGroup check inline>
                      <Input
                        type="checkbox"
                        id="claimedSchoolsOnly"
                        onChange={() => setClaimedSchoolsOnly(!claimedSchoolsOnly)}
                        checked={claimedSchoolsOnly}
                      />
                      <Label for="claimedSchoolsOnly" check>
                        Claimed Schools Only
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>
          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {admissionPagination.totalCount} School Admission Inquiries Found
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table  table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">#ID</th>
                        <th scope="col">Name / Phone / Email</th>
                        <th scope="col">Date</th>
                        <th scope="col">Subject / Message</th>
                        <th scope="col">Action Taken</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(admissionInquiry) ? (
                        admissionInquiry?.map((data, index) => (
                          <tr
                            key={index}
                            // className={classNames({ 'bg-danger': data.deleted }, { 'bg-success': data?.actionTaken })}
                          >
                            <td>{data?.id}</td>
                            <td>
                              <strong>{data?.name}</strong> ({data?.phoneNumber}
                              ) <br />
                              {data?.email}
                            </td>
                            <td className="text-nowrap">{printDateTimeTwoLines(data.createdDate)}</td>

                            <td>
                              #{data?.school?.id} -
                              <a
                                target="_blank"
                                href={`${config.production_url}/school/${data?.school?.slug}`}
                                rel="noreferrer">
                                {convertHtmlToText(data.school?.name)}
                              </a>
                              <br />
                              {data.message}
                            </td>
                            <td>
                              <Badge className={`${data?.actionTaken ? 'bg-success' : 'bg-danger'}`}>
                                {data?.actionTaken ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={`${data?.deleted ? 'bg-success' : 'bg-danger'}`}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>

                            <td>
                              {(!data?.actionTaken || !data?.deleted) && (
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" caret className="bg-transparent borderless text-dark">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    {!data?.actionTaken && (
                                      <DropdownItem tag="a" onClick={() => markActionTaken(data)}>
                                        <i className="fas fa-user-cog text-success m-1 pe-3 " />
                                        Mark Action Taken
                                      </DropdownItem>
                                    )}
                                    {!data?.deleted && (
                                      <DropdownItem tag="a" onClick={() => markDeleted(data)}>
                                        <i className="fas fa-trash-alt text-danger m-1 pe-3 " />
                                        Delete
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={admissionPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default AdmissionInquiryList;
