// NOTE: Commented ones to be implemented
import { ARTICLE_LINKS } from 'containers/article/routes';
import { CMS_LINKS } from 'containers/cms/routes';
import { COMMUNITY_LINKS } from 'containers/community/routes';
import { TAXONOMY_LINKS } from 'containers/taxonomy/routes';
import { TPZ_LINKS } from 'containers/tpz/routes';
import LOGIN_LINKS from '../containers/authentication/links';
import BABY_NAME_LINKS from '../containers/babyName/links';
import BLOG_LINKS from '../containers/blog/links';
import CALCULATORS_LINKS from '../containers/calculators/links';
import COLLEGE_LINKS from '../containers/college/links';
import DASHBOARD_LINKS from '../containers/dashboard/links';
import DOCTORS_LINKS from '../containers/doctors/links';
import FORUM_LINKS from '../containers/forum/links';
import GENERATE_SITEMAP_LINKS from '../containers/generateSitemap/links';
import CONTACT_INQUIRY_LINKS from '../containers/inquiry/links';
import LOCATION_LINKS from '../containers/location/links';
import OTHER_LINKS from '../containers/others/links';
import SCHOOL_LINKS from '../containers/school/links';
import USER_LINKS from '../containers/user/links';
import WORKSHEETS_LINKS from '../containers/worksheets/links';
import { PUBLIC_URL } from './constants';

export const SMKLinks = {
  HOME_PAGE: `${PUBLIC_URL}/`,
  ERROR_404: `${PUBLIC_URL}/errors/error-404`,
  ...FORUM_LINKS,
  ...LOGIN_LINKS,
  ...DASHBOARD_LINKS,
  ...WORKSHEETS_LINKS,
  ...USER_LINKS,
  ...CONTACT_INQUIRY_LINKS,
  ...BABY_NAME_LINKS,
  ...SCHOOL_LINKS,
  ...CALCULATORS_LINKS,
  ...GENERATE_SITEMAP_LINKS,
  ...LOCATION_LINKS,
  ...OTHER_LINKS,
  ...DOCTORS_LINKS,
  ...TPZ_LINKS,
  ...ARTICLE_LINKS,
  ...TAXONOMY_LINKS,
  ...COLLEGE_LINKS,
  ...BLOG_LINKS,
  ...CMS_LINKS,
  ...COMMUNITY_LINKS,
};
